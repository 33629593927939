<template>
	<div class="invoice-data">
		<div class="header acea-row row-between-wrapper" ref="header">
	      <div class="black" @click="returnChange()">
	        <img src="@assets/images/icon_black.png" alt="" />
	      </div>
	      <p>电池保护板打样参数</p>
	    </div>
	  	<div class="set-container">
			<div class="set-list acea-row row-between">
				<p>编号：</p>
				<p class="right">
					SXLI202001
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>客户名称：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>项目名称：</p>
				<p class="right">
					
				</p>
			</div>
		</div>
		<div class="set-container">
			<div class="set-list acea-row row-between">
				<p>电芯类别：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>倍率：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>串联：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>并联：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>工作电压：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>过流保护电流：</p>
				<p class="right">
					
				</p>
			</div>


			<div class="set-list acea-row row-between">
				<p>最大充电电流：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>正常持续工作电流：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>保护板充放电形式：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>焊盘要求：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>焊盘中间间距：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>保护板厚度：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>保护板宽度：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>保护板高度：</p>
				<p class="right">
					
				</p>
			</div>
		</div>


		<div class="set-container last" >
			<div class="set-list acea-row row-between">
				<p>充电均衡启动电压：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>均衡电流：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>温度保护要求（充放电）：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>过充延时：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>过放延时：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>电量计方案（IC型号）：</p>
				<p class="right">
					
				</p>
			</div>


			<div class="set-list acea-row row-between">
				<p>通讯协议：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>期望打样周期：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>使用场景：</p>
				<p class="right">
					
				</p>
			</div>
			<div class="set-list acea-row row-between">
				<p>备注：</p>
				<p class="right">
					
				</p>
			</div>
		</div>

		<div class="detail-btn acea-row row-between">
	      <div class="right pay bule" :class="firstJum? 'glay':''">
	        <p>返回</p>
	      </div>
	    </div>
	</div>
</template>

<script type="text/javascript">
export default {
	name:'lithiumParamDianxin',
	data(){
		return {

		}
	},
	mounted(){

	},
	methods:{
		//返回上一页
		returnChange() {
	      var that = this;
	      that.$router.go(-1);
	    },

	}
}
</script>

<style type="text/css">
.invoice-data { padding-top:50px; }

.invoice-data .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.invoice-data .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.invoice-data .header .black img {
  width: 100%;
  height: 100%;
}
.invoice-data .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}

.set-container.last { 
	padding-bottom:50px;
}
</style>


